import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
@Component({
  name: 'GtrRegistrationModuleContentPagesView',
  computed: {
    ...mapState('contentpages', ['contentpages'])
  }
})
export default class GtrRegistrationModuleContentPagesView extends Vue {
  data () {
    return {
      loading: false,
      contentPages: [],
      contentPageToDelete: null
    }
  }

  @Watch('contentpages')
  onContentPagesChange (payload: any) {
    this.$data.contentPages = []
    Object.entries(payload).map((entry: any) => {
      this.$data.contentPages.push({
        name: entry[0],
        value: entry[1]
      })
    })
  }

  async mounted () {
    const contentPages = this.$store.state.contentpages.contentpages
    if (Object.keys(contentPages).length > 0) {
      this.$data.contentPages = []
      Object.entries(contentPages).map((entry: any) => {
        this.$data.contentPages.push({
          name: entry[0],
          value: entry[1]
        })
      })
    } else {
      await this.fetchContentPages()
    }
  }

  handleDeleteContentPage (payload: any) {
    this.$data.contentPageToDelete = payload
  }

  handleEditContentPage (contentPage: any) {
    this.$router.push({
      name: 'level-two.modules.registration.content-pages.edit',
      params: { content_page_uuid: contentPage.value.uuid }
    })
  }

  async onDeleteContentPageAction (payload: any) {
    if (payload.confirm) {
      try {
        await this.$store.dispatch('contentpages/deleteContentPage', {
          event_uuid: this.$route.params.event_uuid,
          name: this.$data.contentPageToDelete.name
        })
        Container.get(Notification).success('Content page successfully deleted.')
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      }
    }
    this.$data.contentPageToDelete = null
  }

  private async fetchContentPages () {
    try {
      const { event_uuid } = this.$route.params
      this.$data.loading = true
      await this.$store.dispatch('contentpages/getContentPages', { event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }
}
